@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;700&family=Montserrat&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --slate-midnight: #212121;
  --slate-dark: #2C2C2C;
  --slate-dark-gradient: #444444;
  --slate-medium: #666666;
  --slate-light: #999999;
  --snow-light: #FDFDFD;
  --gold-plate: #DCA54C;
  --gold-dark: #b47f2a;
  --gold-highlight: #EACA57;
  background-color: var(--slate-midnight);
}

html {
  overflow-x: hidden;
  margin: 0;
  font-family: 'League Spartan', 'Roboto','Montserrat' sans-serif;
  font-weight: 400;
  color: var(--snow-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  box-sizing: border-box;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section{
  /* margin-bottom: 8rem; */
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}

@media screen and (min-width: 768px) {
  section{
    margin-bottom: 8rem;
  }
}

.nav-logo {
  font-family: 'Space Grotesk', sans-serif;
  font-weight: 400;
}

h1{
  font-family: "Montserrat";
}

h2{
  font-family: "League Spartan";
  font-weight: 300;
}

h3{
  font-family: "League Spartan";
  font-weight: 200;
}

h4{
  font-family: "League Spartan";
  font-weight: 400;
}

p{
  font-family: "Roboto";
  font-weight: 400;
}

.hero-blurb{
  font-family: "League Spartan";
  font-weight: 400;
}

.hero-images-grid-bg-gradient{
  background: radial-gradient(#D9D9D945, var(--slate-midnight), var(--slate-midnight));
}

@media screen and (max-width: 640px){
  #latest-works{
    width: 100%;
  }
  #works-headings{
    width: 80%;
    margin: 0 auto;
  }
  #grid-container{
    overflow-x: scroll;
  }
}

.grid-container{
  display: grid; 
  box-sizing: border-box;
  padding-top: 1%;
  padding-bottom: 1%;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  grid-template-rows: repeat(10, minmax(min-content, 15vh)); /* Add 3 to the first argument for each now card 'row' you want */
  gap: 1.1rem;
}

.item-base{
  height: 100%;
}

.mask-overlay{
  content: '';
  width: 100%;
  height: 110%;
  top: 0;
  left: 0;
  background: linear-gradient(0.30turn,var(--slate-midnight) 20%, transparent, transparent);
}


@media screen and (min-width:1900px){
  .large-padding{
    padding-top: 15%;
    padding-bottom: 15%;
  }
}

.center-vertical{
  top: 0;
  transform: translateY(-50%);
}

.radio-grid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 0.25rem;
}

.prod-type-grid{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

@media screen and (min-width: 640px){
  .radio-grid{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    gap: 0.25rem;
  }
  .prod-type-grid{
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(1, 1fr);
    ;
  }
}

@media screen and (min-width: 1280px){
  .radio-grid{
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 0.25rem;
  }
}

.radio-item{
  width: 100%;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  cursor: pointer;
}

input[type="radio"]{
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--slate-dark-gradient);
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15rem;
  height: 1.15rem;
  border: 0.125rem solid currentColor;
  border-radius: 50%;
  transform: translateY(0.25rem);
  display: grid;
  place-content: center;
  cursor: pointer;
}

input[type="radio"]::before{
  content: "";
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
  transform: scale(0);
  transition: 150ms transform ease-in-out;
  box-shadow: inset 1rem 1rem var(--gold-plate);
  background-color: CanvasText;
}

input[type="radio"]:checked::before{
  transform: scale(1);
}

input{
  color: var(--slate-dark-gradient);
}

@media screen and (min-width: 1536px) {
  .dev-process-container{
    max-width: 1536px;
  }
}

#process-grid{
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr ;
  grid-template-rows: repeat(7,1fr);
  margin-top: 5vh;
}

.text-container, .img-container{
  width: 100%;
  padding-right: 4vw;
  padding-bottom: 4vw;
  justify-self: center;  
  transition: 150ms all ease-in-out;
}
@media screen and (min-width: 768px){ 
  .text-container, .img-container{
    padding-right: 0;
    width: 70%;
    transition: 150ms all ease-in-out;
  }
}

.number-container{
  overflow-y: hidden;
  text-align:center;
  /* width: min-content; */
}

@media screen and (min-width: 768px){
  .number-container{
    width: min-content;
  }
}

.number-style{
  font-family: "Montserrat";
  font-size: 2rem;
  font-weight: 900;
}

.systems-grid{
  display: grid;
  grid-template-columns: 1;
  grid-template-rows: repeat(6, 1fr);
}

@media screen and (min-width: 768px){
  .systems-grid{
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3,1fr);
  }
}

@media screen and (min-width: 2200px){
  .systems-grid{
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(1,1fr);
  }
}